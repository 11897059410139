import React, { Component } from 'react';
import '../App.css';
import gM1 from '../assets/gM1.jpg';
import gM2 from '../assets/gM2.jpg';
import gM3 from '../assets/gM3.jpg';
import gM4 from '../assets/gM4.jpg';
import gM5 from '../assets/gM5.jpg';
import gM6 from '../assets/gM6.jpg';
import video1 from '../logo/video1.mp4'
import video2 from '../logo/video2.mp4'

class Why extends Component {




    render() {
        return (
            <div class="WhyTop">
                <div class="boxWrap2">

                    <div class="utilityLeft"> <span class="utilityHeader">UTILITIES</span>

                        <div class="utilityDetails">
                            <p class="box">Teen Chimps Empire will have its own metaverse play-to-earn game getting regular updates as
                                technology progresses. Our team is determined to create a game that’s fitting for both gamers &
                                cryptoheads alike.
                            </p>

                            <p class="box">Every Teen Chimp Holder gets an airdrop of our metaverse gaming token which will be used in
                                all our gaming projects. Our team’s main focus is to develop a fun & playable game with an
                                exciting reward system. We are optimistic about the rise in the value of our in-game token.
                            </p>

                            <p class="box">
                                <p>When you mint a Gen 2 NFT you receive a 1-1 airdrop of our final Gen 3 collection which will launch in Q2 of 2023. Each Gen 2 NFT will be a customisable and playable character in our P2E Metaverse.</p>
                                <p>Chimps from each generation will receive an airdrop of our gaming token Pepeso on Q4 2022.</p>
                                <p>Trading for Pepeso on native Dexes on “FTM” will begin from Q2 of 2023. We are optimistic on launching the Beta version of our game during that time to help price movement for “Pepeso”.</p>
                            
                            </p>
                        </div>

                        <div class="gameMonkeys">
                            <img class="gMPic" src={gM1} alt="Teen Chimps Empire" />
                            <img class="gMPic" src={gM2} alt="Teen Chimps Empire" />
                            <img class="gMPic" src={gM3} alt="Teen Chimps Empire" />
                            <img class="gMPic" src={gM4} alt="Teen Chimps Empire" />
                            <img class="gMPic" src={gM5} alt="Teen Chimps Empire" />
                            <img class="gMPic" src={gM6} alt="Teen Chimps Empire" />
                        </div>

                        <div class="vidCont">
					<video class="videoBg" src={video1} autobuffer autoPlay loop muted playsInline></video>
                    <video class="videoBg" src={video2} autobuffer autoPlay loop muted playsInline></video>

                    
                    </div>

                    </div>


                </div>
            </div>


        )
    }
}

export default Why;

