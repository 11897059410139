import React, { Component } from 'react';
import '../components/styles/carousel.css';
import d1 from '../logo/21.jpeg';
import d2 from '../logo/22.jpeg';
import d3 from '../logo/23.jpeg';
import d4 from '../logo/24.jpeg';
import d5 from '../logo/25.jpeg';
import d15 from '../logo/26.jpeg';
import d6 from '../logo/27.jpeg';
import d7 from '../logo/28.jpeg';
import d8 from '../logo/29.jpeg';
import d9 from '../logo/30.jpeg';
import d10 from '../logo/31.jpeg';
import d11 from '../logo/32.jpeg';
import d12 from '../logo/1.jpeg';
import d13 from '../logo/2.jpeg';
import d14 from '../logo/3.jpeg';







class Carousel extends Component {




    render() {
        return (

<div>
                      
            <div class="slider">
                <div class="slide-track">
                <div class="slide">
                        <img class="carouselIMG" src={d1} alt="d5"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d2} alt="d5"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d3} alt="d5"  />
                    </div> 
                    <div class="slide">
                        <img class="carouselIMG" src={d4} alt="d5"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d5} alt="d5"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d6} alt="d6"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d7} alt="d7"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d8} alt="d8"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d9} alt="d9"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d10} alt="d10"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d11} alt="d11"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d12} alt="d12"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d13} alt="d13"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d14} alt="d14"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d5} alt="d5"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d6} alt="d6"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d7} alt="d7"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d8} alt="d8"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d9} alt="d9"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d10} alt="d10"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d11} alt="d11"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG"  src={d12} alt="d12"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d13} alt="d13"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d14} alt="d14"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d15} alt="d5"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d6} alt="d6"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d7} alt="d7"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d8} alt="d8"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d9} alt="d9"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d10} alt="d10"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d11} alt="d11"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d12} alt="d12" />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d13} alt="d13"  />
                    </div>
                    <div class="slide">
                        <img class="carouselIMG" src={d14} alt="d14"  />
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default Carousel;        