import React, { Component } from 'react';
import '../App.css';
class Team extends Component {
    async componentDidMount () {         

    }

    render() {
        return (<div class="teamHeader">
                <div class="teamTitle">TEAM ROLE</div>

            <div class="group">
                <div class="teamD">
                    <div class="guy1" >
                        <div class="man1 "></div>
                        <div class="name1">Yuralisa</div>
                        <div class="name2">Artist</div>

                    </div>

                    <div class="guy2">
                        <div class="man2"></div>
                        <div class="name1">Doubleyou</div>
                        <div class="name2">Marketing</div>
                    </div>

                    <div class="guy2">
                        <div class="man7"></div>
                        <div class="name1">Alex Jacobi</div>
                        <div class="name2">Business Development & Advisor</div>
                    </div>

                    <div class="guy3">
                        <div class="man3"></div>
                        <div class="name1">Dabeat</div>
                        <div class="name2">Graphics & Game Development</div>


                    </div>

                    <div class="guy4">
                        <div class="man4"></div>
                        <div class="name1">Buccii</div>
                        <div class="name2">Treasury Manager</div>


                    </div>

                    <div class="guy5">
                        <div class="man5"></div>
                        <div class="name1">Jay</div>
                        <div class="name2">Community Management</div>


                    </div>

                    <div class="guy5">
                        <div class="man6"></div>
                        <div class="name1">AppsLKofficial</div>
                        <div class="name2">Web & Blockchain Developer</div>


                    </div>

            </div>

        </div>
        
        </div>)
    }
}

export default Team;