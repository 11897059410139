import './App.css';
//import contract from './NFT';
import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Web3 from 'web3';
import { BrowserRouter, Routes } from 'react-router-dom';
import Footer from "./components/footer";
import Story from "./components/story";
import FAQ from "./components/faq";
import Why from "./components/Why"
import Roadmap from "./components/roadmap";
import Carousel from "./components/carousel"
import { SocialIcon } from 'react-social-icons';
import Team from "./components/team";
import web3 from "web3";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";
import newRoadmap from "./components/newRoadmap";
import Utility from './components/utility';
import Countdown from 'react-countdown';
import bg from './logo/monkey.png';
import detectEthereumProvider from '@metamask/detect-provider';
import './counter.css';
//import CircularProgress from '@material-ui/core/CircularProgress';
//										<div class="mintedAmount">Minted So Far : {this.state.totalSupply} / 2222</div>


const website = () => {
	window.open("https://paintswap.finance/");
}

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let maxMintNFTs;
let onlyLeft;
let now;
let gasLimit = 285000;
let public_sale_cost = ""
let publicSale = ""
let owner;
let public_mint_status = "";
let MAX_PER_WALLET = "";

//<div><img class="headerImg" src={header} alt="TCE_header"/></div>

const ABI = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "_holders",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_quantities",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0xa757B4cF6A33470B7a76051483A40953272084be";
let contract;
// Random component
//const Completionist = () => <span>You are good to go!</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns
	return <div class="counterBlock"><div class="days">{days}</div>:<div class="days">{hours}</div>:<div class="days">{minutes}</div>:<div class="sec">{seconds}</div></div>;
	/*	}*/
};
class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "35000000000000000000",
		presaleValue: "35000000000000000000",
		maxmint: '',
		stateNow: Date.now(),
		story: true,
		utility: true,
		faq: true,
		roadmap: true,
		team: true,
		contactUs: true,
		msg: '',
		_public_sale_cost: "",
		myNFTWallet: "",
		_public_mint_status: "",
		_MAX_PER_WALLET: ""

	}
	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (totalSupplyNFT < 2222) {

			onlyLeft = 2222 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}
			valueOfNFTs = mintAmount * 35000000000000000000;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });

		} else {
			this.setState({ msg: "SOLD OUT!" });
		}





	}

	onSubmitPlus = async event => {
		event.preventDefault();







		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (totalSupplyNFT < 2222) {

			onlyLeft = 2222 - totalSupplyNFT;

			if (mintAmount > onlyLeft /*onlyLeft*/) {
				mintAmount = onlyLeft;
			}
			valueOfNFTs = mintAmount * 35000000000000000000;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });

		} else {
			this.setState({ msg: "SOLD OUT!" });
		}



	}

	onSubmit = async event => {
		event.preventDefault();

		const provider = await detectEthereumProvider();

		if (provider) {

			console.log('Ethereum successfully detected!');



			// From now on, this should always be true:
			// provider === window.ethereum

			// Access the decentralized web!

			//.....................................................................//

			(async () => {

				const chainId = 250;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainName: 'Fantom Opera',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Fantom Opera', decimals: 18, symbol: 'FTM' },
										/*rpcUrls: ['https://rpcapi.fantom.network'],*/
										rpcUrls: ['https://rpc.ankr.com/fantom'],

									},
								],
							});
						}
					}
				}




				if (window.ethereum) {
					await window.ethereum.send('eth_requestAccounts');
					window.web3 = new web3(window.ethereum);
					//.....................................................................//
					//.....................................................................//


					contract = new window.web3.eth.Contract(ABI, address);


					let accounts = await window.web3.eth.getAccounts();
					account = accounts[0];
					this.setState({ walletAddress: account });

					console.log("Wallet Address" + this.state.walletAddress);

					try {
						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });

						console.log("Total Supply:" + totalSupplyNFT);


						if (totalSupplyNFT < 2222) {
							onlyLeft = 2222 - totalSupplyNFT;

							this.setState({ totalValue: 35000000000000000000 });



						} else {

							this.setState({ msg: "SOLD OUT" });

						}

						console.log("Value:" + this.state.totalValue);



					} catch (err) {
						console.log("err: " + err);

					}

				}


			})();

			//.....................................................................//






			// Legacy providers may only have ethereum.sendAsync
			const chainId = await provider.request({
				method: 'eth_chainId'
			})

		} else {

			// if the provider is not detected, detectEthereumProvider resolves to null
			console.error('Please install MetaMask!');
			alert('A valid provider could not be found!');

		}


	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);


		try {
			let owner = await contract.methods.owner().call();

			if (account != owner) {


				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);
					console.log("cost : " + this.state.totalValue);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 1 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + mintAmount);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}

			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);



				} catch (err) {

					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}
			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Aterium Universe", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
			console.log("I'm LOGGED IN");
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
			console.log("provider :" + provider);

			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {

				//	(async () => {

				if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {

					const chainId = 250;

					if (window.ethereum.networkVersion !== chainId) {
						try {
							await window.ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											chainName: 'Fantom Opera',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Fantom Opera', decimals: 18, symbol: 'FTM' },
											/*rpcUrls: ['https://rpcapi.fantom.network'],*/
											rpcUrls: ['https://rpc.ankr.com/fantom'],

											/*chainName: 'Mumbai Testnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/

											/*chainName: 'Rinkeby Test Network',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
										},
									],
								});
							}
						}
					}



					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);

						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 2222 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;

							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 2222) {

								onlyLeft = MAX_PER_WALLET - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = MAX_PER_WALLET - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * 35000000000000000000;

								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}


					} catch (err) {
						console.log("err: " + err);

					}
				} else if (web3Modal.cachedProvider == "walletconnect") {

					const chainId = 250;

					if (WalletConnectProvider.networkVersion !== chainId) {
						try {
							await WalletConnectProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											chainName: 'Fantom Opera',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Fantom Opera', decimals: 18, symbol: 'FTM' },
											/*rpcUrls: ['https://rpcapi.fantom.network'],*/
											rpcUrls: ['https://rpc.ankr.com/fantom'],

											/*chainName: 'Rinkeby Test Network',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://rinkeby.infura.io/v3/'],*/

											/*chainName: 'Mumbai Testnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
										},
									],
								});
							}
						}
					}


					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);


						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 2222 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;

							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 2222) {

								onlyLeft = 0;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = MAX_PER_WALLET - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 35000000000000000000;

								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}


					} catch (err) {
						console.log("err: " + err);

					}
				} else if (web3Modal.cachedProvider == "coinbasewallet") {

					const chainId = 250;

					if (CoinbaseWalletProvider.networkVersion !== chainId) {
						try {
							await CoinbaseWalletProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await CoinbaseWalletProvider.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											chainName: 'Fantom Opera',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Fantom Opera', decimals: 18, symbol: 'FTM' },
											/*rpcUrls: ['https://rpcapi.fantom.network'],*/
											rpcUrls: ['https://rpc.ankr.com/fantom'],

											/*chainName: 'Mumbai Testnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
										},
									],
								});
							}
						}
					}



					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);

						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 2222 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;


							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 2222) {

								onlyLeft = 2222 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = 2222 - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * 35000000000000000000;

								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}


					} catch (err) {
						console.log("err: " + err);

					}


				}


				//})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	async componentDidMount() {





		/*	try {
				totalSupplyNFT = await contract.methods.totalSupply().call();
				this.setState({ totalSupply: totalSupplyNFT });
	
					console.log("Total Supply:"+totalSupplyNFT);
					//...........................................//
					console.log("Date =" + Date.now());
					//...................................................//
	
				if (totalSupplyNFT < 200) {
					onlyLeft = 200-totalSupplyNFT;
					
					maxMintNFTs = onlyLeft ;
					
	
				}else{
	
					onlyLeft = 3000 - totalSupplyNFT;
					maxMintNFTs = onlyLeft ;
					this.setState({totalValue: mintAmount * 20000000000000000})
	
				}
	
				this.setState({ maxmint: maxMintNFTs })
				console.log("Max :" + this.value.maxmint)
	
	
			} catch (err) {
	
			}

<div class="headers">
					<div class="name">COOL BIRDS</div>
					<div>
						SocialIcons
					</div>

					<div class="connect">
					<button class="wallet" >CONNECT WALLET</button>
				   </div>

				</div>





	*/
	}

	render() {

		return (


			<div class="bg">

				<div class="headers" id="navbarTop">
					<div class="title"></div>

					<div class="rightNav">
						<div class="navBar">

							<div><Link activeClass="navBar" to="story" spy={true} smooth={true} duration={550}>STORY</Link></div>
							<div><Link activeClass="navBar" to="utility" spy={true} smooth={true} duration={550}>UTILITIES</Link></div>
							<div><Link activeClass="navBar" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
							<div><Link activeClass="navBar" to="roadmap" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>
							<div><Link activeClass="navBar" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>
							<div><Link activeClass="navBar" to="footer" spy={true} smooth={true} duration={550}>CONTACT US</Link></div>

						</div>

						<div class="socialContainer">
							<div class="discord">
								<SocialIcon class="discord" url="https://instagram.com/chimps_teen?r=nametag" target="_blank" network="instagram" bgColor="#ee314c" style={{ height: 25, width: 25 }} />
							</div>

							<div class="discord">
								<SocialIcon class="discord" url="https://discord.gg/RAwNH92UkF" target="_blank" network="discord" bgColor="#ee314c" style={{ height: 25, width: 25 }} />
							</div>

							<div class="twitter">
								<SocialIcon class="twitter" url="https://twitter.com/chimps_teen?s=21" target="_blank" network="twitter" bgColor="#ee314c" style={{ height: 25, width: 25 }} />
							</div>
						</div>
					</div>
				</div>

				<Carousel />

				{this.state.stateNow >= 1650412800000/*1647347100000*/ ? (
					<Element name="story">
						<Story />

						{this.state.msg !== "SOLD OUT" ? (

							<div class="nftblockWalletConnectedALL">

								{
									this.state.walletAddress === '' ? (


										<div class="walletConnect">
											<form onSubmit={this.walletConnect}>


												<button disabled class="wallet3" type='submit'>Minting Closed</button>
											</form>

										</div>
									) : (<div>


										<div class="minting_count_button">
											<div>
												<form onSubmit={this.onSubmitMinus}>
													<button class="btnfos-0-2" type="submit">-</button>
												</form>
											</div>

											<div>
												<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
											</div>


											<div>
												<form onSubmit={this.onSubmitPlus}>
													<button class="btnfos-0-2" type="submit">+</button>
												</form>
											</div>


										</div>

										<div class="mintbuttondiv">
											<form onSubmit={this.onSubmit2}>

												<button class="btnfos-0-3" type="submit">
													Mint for {this.state.totalValue / 1000000000000000000} FTM</button>
											</form>
										</div>
										<div>

											{this.state.statusError ? (
												<div class="errorMessage">
													<div >Sorry, something went wrong please try again later</div>
												</div>)
												: null}

											{this.state.statusLoading ? (
												<div class="loadingContainer">
													<div>
														<div class="loadingText"> Minting Your Teen Chimps</div>
														<div class="loadTextSub">(this can take up to 30 seconds)</div>
													</div>

												</div>)
												: null}

											{this.state.success ? (

												<div class="successfully">MINTING SUCCESSFUL! </div>)
												: null}

										</div></div>)
								}
							</div>
						) : (

							<div class="walletConnect">



								<button class="wallet3" type='submit'>SOLD OUT</button>


							</div>

						)}

					</Element>)
					: (

						<Element name="story">
							<Story />


							<div class="countdown">
								<Countdown
									date={1650412800000/*1647347100000*/}
									renderer={renderer}
								/>
							</div>

						</Element>
					)
				}


				{this.state.utility == true ? (
					<Element name="utility">
						<Why />
					</Element>
				) : null}


				{this.state.faq == true ? (
					<Element name="faq">
						<FAQ />
					</Element>
				) : null}

				{this.state.roadmap == true ? (
					<Element name="roadmap">
						<Roadmap />
					</Element>
				) : null}

				{this.state.team == true ? (
					<Element name="team">
						<Team />
					</Element>
				) : null}

				{this.state.contactUs == true ? (
					<Element name="footer">
						<Footer />
					</Element>
				) : null}

				<img class="pic" src={bg} alt="monkey" />

				<div class="buttonsDown">
					<div class="btnSet">
						<div class="storyB">
							<div>
								<Link activeClass="storyB2" to="story" spy={true} smooth={true} duration={550}>STORY</Link></div>
						</div>
						<div class="storyB">
							<div>
								<Link activeClass="storyB2" to="utility" spy={true} smooth={true} duration={550}>UTILITIES</Link></div>
						</div>
						<div class="storyB">
							<div>
								<Link activeClass="storyB2" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
						</div>
						<div class="storyB">
							<div>
								<Link activeClass="storyB2" to="roadmap" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>
						</div>
						<div class="storyB">
							<div>
								<Link activeClass="storyB2" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>
						</div>
						<div class="storyB">
							<div>
								<Link activeClass="storyB2" to="footer" spy={true} smooth={true} duration={550}>CONTACT US</Link></div>
						</div>
					</div>
				</div>
			</div>)
	}
}

export default App;
