import React, { Component } from 'react';
import '../App.css';






class Story extends Component {




    render() {
        return (
            <div class="boxWrap2">

                <div class="storyLeft"> <div class="storyHeader">TEEN CHIMPS EMPIRE</div>

                    <div class="storyDetails"><p>The chimps have turnt 18 and are now ready to battle for Honor in the Chimpsverse. Design your custom chimp and enjoy intense team battle with people from around the world in our metaverse.
                    </p>
                        
                    </div>

                    
                </div>

                <div class="storyPicContainer">
                </div>

            </div>

        )
    }
}

export default Story;

