import React, { Component } from 'react';
import '../App.css';

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div class="accordin">

          <div class="faqTitle">FAQ</div>

          <details >
            <summary>How do I buy a Teen Chimp ?</summary>
            <div class="faq__content">
              <p>Once minting starts you will be able to mint here on our website at 35 FTM. You can visit our
                twitter and discord to ensure that you are using the legitimate T.C.E website</p>
            </div>
          </details>
          <details>
            <summary>When is the sale/minting day?</summary>
            <div class="faq__content">
              <p>Minting for Teen Chimps Empire will begin on 15th March 2022.</p>

            </div>
          </details>
          <details>
            <summary>Where will the digital artwork be stored?</summary>
            <div class="faq__content">
              <p>Teen Chimps Empire will be stored on IPFS, a decentralized data storage that backs
                data with sustainable and perpetual endowments, allowing users and developers to truly
                store data forever.
              </p>

            </div>
          </details>

          <details>
            <summary>Do I own my Teen Chimp after minting completely?</summary>
            <div class="faq__content">
              <p>Yes, including the right to monetize commercially. Full intellectual property rights of each
                Teen Chimp are owned by its purchaser.
              </p>

            </div>
          </details>

          <details>
            <summary>Why is T.C.E created on Fantom and not on other chains?</summary>
            <div class="faq__content">
              <p>Because we believe every NFT collector deserves the high speed and low fees Fantom
                offers.</p>

            </div>
          </details>

        </div>
      </div>


    )
  }
}

export default FAQ;

