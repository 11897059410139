import React, { Component } from 'react';
import '../App.css';





class newRoadmap extends Component {




    render() {
        return (
            
            <div class="mainRoad">

                <div class="detailBox1">Launch of Cool Birds</div>

            </div>

        )
    }
}

export default newRoadmap;

