/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import '../Roadmap.css';
import "aos/dist/aos.css";
import Aos from 'aos';

class Roadmap extends Component {


	render() {
		return (
			<div>
                <div class="roadmapH">ROADMAP</div>

				<div class="timeline">
					<div  data-aos="fade-right" data-aos-duration="1500" class="timeline__event ">
						<div class="timeline__event__icon_hotpink">
							<div class="lni-cake">2022</div>

						</div>
						<div class="timeline__event__date">
							Q4
						</div>
						<div class="timeline__event__content ">
							
							<div class="timeline__event__description">
								<p>Begin Presale for our metaverse utility token“Pepeso”</p>
								<p>Airdrop “Pepeso” to Gen 1 and Gen 2 holders</p>
							</div>
						</div>
					</div>
					<div data-aos="fade-left" data-aos-duration="1500" class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
						<div class="timeline__event__icon_hotpink">
							<div class="lni-burger"> 2022 </div>

						</div>
						<div class="timeline__event__date">
						Q1
						</div>
						<div class="timeline__event__content">
							
							<div class="timeline__event__description">
								<p>End Presale for “Pepeso”</p> 								
							</div>
						</div>
					</div>
					<div data-aos="fade-right" data-aos-duration="1500" class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
						<div class="timeline__event__icon_hotpink">
							<div class="lni-slim">2023</div>

						</div>
						<div class="timeline__event__date">
						Q2
						</div>
						<div class="timeline__event__content">
							
							<div class="timeline__event__description">
								<p>Make Beta Game environmental live on web for users to feel the game firsthand</p>
								<p>Marketing for Game by influencers with a separate digital marketing</p>
							
							</div>

						</div>
					</div>
					<div  data-aos="fade-left" data-aos-duration="1500" class="timeline__event animated fadeInUp timeline__event--type1">
						<div class="timeline__event__icon_hotpink">
							<div class="lni-cake">2023</div>

						</div>
						<div class="timeline__event__date">
						Q3
						</div>
						<div class="timeline__event__content">
							
							<div class="timeline__event__description">
								<p>Launch “Pepeso” on $FTM native Dexes</p>
							</div>
						</div>
					</div>
					<div data-aos="fade-right" data-aos-duration="1500" class="timeline__event animated fadeInUp timeline__event--type1">
					<div class="timeline__event__icon_hotpink2">
							<div class="lni-cake">2023</div>

						</div>
						<div class="timeline__event__date">
						Q4
						</div>
						<div class="timeline__event__content">
						
							<div class="timeline__event__description">
								<p>Launch Beta Game version for TCE holders to play</p>
								<p>Apply for “Pepeso” listing on different “CEX”</p>
							</div>
						</div>
					</div>
					<div data-aos="fade-left" data-aos-duration="1500" class="timeline__event animated fadeInUp timeline__event--type1">
					<div class="timeline__event__icon_hotpink2">
							<div class="lni-cake">2023</div>

						</div>
						<div class="timeline__event__date">
						Q4
						</div>
						<div class="timeline__event__content">
						
							<div class="timeline__event__description">
								<p>Launch Beta Game version for TCE holders to play</p>
								<p>Apply for “Pepeso” listing on different “CEX”</p>
							</div>
						</div>
					</div>

					<div data-aos="fade-right" data-aos-duration="1500" class="timeline__event animated fadeInUp timeline__event--type1">
					<div class="timeline__event__icon_hotpink2">
							<div class="lni-cake">2024</div>

						</div>
						<div class="timeline__event__date">
						Q1
						</div>
						<div class="timeline__event__content">
						
							<div class="timeline__event__description">
								<p>Launch game officially worldwide</p>
							</div>
						</div>
					</div>

					<div data-aos="fade-left" data-aos-duration="1500" class="timeline__event animated fadeInUp timeline__event--type1">
					<div class="timeline__event__icon_hotpink2">
							<div class="lni-cake">2024</div>

						</div>
						<div class="timeline__event__date">
						Q2
						</div>
						<div class="timeline__event__content">
						
							<div class="timeline__event__description">
								<p>Launch game on AppStore and Playstore</p>
							</div>
						</div>
					</div>
				</div>
			</div>

		);

	}
}

export default Roadmap;

