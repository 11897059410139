import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';

var mailtoLink = require('mailto-link');

const handle = () => {
	mailtoLink({ to: 'info@teenchimpspire.com', subject: 'Nice mailto lib' });
	console.log("MailCode");
}

const handle2 = () => {
	window.open("https://instagram.com/chimps_teen?r=nametag");
}

const handle3 = () => {
	window.open("https://twitter.com/chimps_teen?s=21");
}

const handle4 = () => {
	window.open("https://discord.gg/RAwNH92UkF");
}



class Footer extends Component {


	render() {

		return (

			<div class="footer">

				<div class="footerHeader">JOIN THE EMPIRE</div>
				<div class="footerP">Join our discord community to stay up to date with project updates, rewards & giveaways.
					For all inquiries please send us an email at info@teenchimpspire.com.

				</div>

				<div class="line"></div>

				<div class="iconsSection1">
					<button class="footerBox1" onClick={handle}>

						<div class="footerDetails"> <a href="mailto:SOPentry@protonmail.com" target="blank">Send Your Message</a>

							<p>info@teenchimpspire.com</p>
						</div>
					</button>
					<button class="footerBox2" onClick={handle2}>
						<div class="footerDetails">INSTAGRAM
							<p>Join the Community</p>
						</div>
					</button>
				</div>

				<div class="iconsSection2">
					<button class="footerBox3" onClick={handle3}>
						<div class="footerDetails">TWITTER
							<p>Tweet Tweet</p>
						</div>
					</button>
					<button class="footerBox4" onClick={handle4}>
						<div class="footerDetails">DISCORD
							<p>Join the Community</p>
						</div>
					</button>
				</div>


			</div>)
	}
}

export default Footer;