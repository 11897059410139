import React, { Component } from 'react';
import '../App.css';




class Utility extends Component {


    render() {
        return (<div class="rmbg">
                <div class="RoadmapTitle">Roadmap</div>

            <div class="mainRoad">
                <div class="liner">
                    
                <div class="detailBox1" >
                    <div class="pic1"></div>
                    <div class="detailTitle">Launch of Cool Birds</div>
                    <div class="detailTitle2">🌈 On Febuary 25 we’re going live!</div>

                </div>

                <div class="line" >
                 </div>   
                </div>



                <div class="liner">
                <div class="detailBox2" >
                    <div class="pic2"></div>
                    <div class="detailTitle">After 50% Sold Out</div>
                    <div class="detailTitle2">👕 Open Merch Store and and give qualifying community members free merch</div>

                </div>

                <div class="line" >
                 </div>   
                </div>




                <div class="liner">
                <div class="detailBox3" >
                    <div class="pic3"></div>
                    <div class="detailTitle">After 100 % Sold Out</div>
                    <div class="detailTitle2">
								<p>💰 Community project fund/ incubator </p>
								<p>&nbsp;</p>
								<p>💚 We will help small up and coming projects with the ETH required to launch their project on the Ethereum Blockchain. Many teams out in the NFT space have great ideas and want to participate in this space we’ve all come to love but, have financial difficulties with the price associated with launching on ETH. So through this incubator fund we can give back to the community and make the space a cooler place. </p>
								<p>&nbsp;</p>
								<p>🤝 The winners of this fund will be chosen by the Cool Bird community. Cool bird Holders will have whitelist access to these projects.</p>



                    </div>

                </div>

                <div class="line" >
                 </div>   
                </div>


                

            </div>
            
            
            
            <div class="mainRoad">
                <div class="liner">

                    <div class="line" >
                 </div> 
                    
                <div class="detailBox2" >
                    <div class="pic4"></div>
                    <div class="detailTitle">Distribution</div>
                    <div class="detailTitle2"><p>📦 50% of secondary market profit will be spent on project development and marketing</p>
</div>

                </div>

                <div class="line" >
                 </div>   
                </div>



                <div class="liner">
                <div class="detailBox2" >
                    <div class="pic5"></div>
                    <div class="detailTitle">DAO</div>
                    <div class="detailTitle2"><p>👪 A DAO will be established for community sustainability.
								</p>
</div>

                </div>

                <div class="line" >
                 </div>   
                </div>




                <div class="liner">
                <div class="detailBox2" >
                    <div class="pic6"></div>
                    <div class="detailTitle">CHARITY</div>
                    <div class="detailTitle2"><p>🤗 Team will donate some ETH to save endangered animals, let’s show them how cool we really are and help them together.</p>

</div>

                </div>

                   
                </div>


                

            </div>
            
            
            
            
            
            
            
            
            
            
            
            
            
            </div>

        );

    }
}

export default Utility;